@use '../../shared_variables.json' as *;
@use 'variables' as *;
@use 'functions' as *;
@use "include-media/dist/_include-media" as *;

@mixin grid-wrapper() {
  display: grid;
  grid-template-columns:
    minmax(map-get($grid, column-gap), 1fr)
    minmax(auto, rem(map-get($container-sizes, default)))
    minmax(map-get($grid, column-gap), 1fr);
  grid-template-rows: auto 1fr auto;
  height: inherit;

  > * {
    grid-column: 2;
    width: 100%;
  }
}

// Full Page Bleed
@mixin fullbleed() {
  height: 100%;
  left: 50%;
  margin-left: calc(-50vw - 1px);
  margin-right: -50vw;
  position: absolute;
  right: 50%;
  top: 0;
  width: calc(100vw + 1px);
  z-index: map-get($z, hidden);
}

/* stylelint-disable declaration-no-important */
@mixin offset-background($size: 125) {
  $offset: rem($size);

  background-image: linear-gradient(
    180deg,
    map-get($backgrounds, light-bg) $offset,
    map-get($backgrounds, sepia-bg) $offset
  ) !important;
  position: relative;

  &::before {
    @include fullbleed();

    background: inherit;
    content: '';
  }
}
/* stylelint-enable declaration-no-important */

@mixin sw-container($fluid: false, $size: map-get($container-sizes, default)) {
  margin: 0 auto;
  max-width: rem($size);
  width: 100%;
}

// ----------------------------------------
// ICONS
// ----------------------------------------

@mixin icon($viewId) {
  background: url('/etc.clientlibs/corp-aem-sherwin/clientlibs/site/resources/sprite.svg##{$viewId}')
    center / 100% 100% no-repeat;
}

@mixin icon-close() {
  @include icon('close-usage');

  height: rem(12px);
  width: rem(12px);
}

@mixin icon-carat-down() {
  @include icon('carat-down-usage');

  height: rem(10px);
  width: rem(10px);
}

@mixin icon-carat-left() {
  @include icon('carat-left-usage');

  height: rem(10px);
  width: rem(10px);
}

@mixin icon-carat-right() {
  @include icon('carat-right-usage');

  height: rem(10px);
  width: rem(10px);
}

@mixin icon-carat-up() {
  @include icon('carat-up-usage');

  height: rem(10px);
  width: rem(10px);
}

@mixin icon-expand() {
  @include icon('expand-usage');

  height: rem(15px);
  width: rem(15px);
}

@mixin icon-collapse() {
  @include icon('collapse-usage');

  height: 1px;
  width: rem(15px);
}

@mixin icon-pindrop() {
  @include icon('pindrop-usage');

  height: rem(15px);
  width: rem(11px);
}

@mixin icon-globe() {
  @include icon('globe-usage');

  height: rem(15px);
  width: rem(15px);
}

@mixin icon-person() {
  @include icon('person-usage');

  height: rem(15px);
  width: rem(17px);
}

@mixin icon-hotspot-circle() {
  @include icon('hotspot-circle-usage');

  height: rem(60px);
  width: rem(60px);
}

@mixin icon-arrow-left() {
  @include icon('arrow-left-usage');

  height: rem(60px);
  width: rem(60px);
}

@mixin icon-arrow-right() {
  @include icon('arrow-right-usage');

  height: rem(60px);
  width: rem(60px);
}

@mixin icon-arrow-next() {
  @include icon('arrow-next-usage');

  height: rem(20px);
  width: rem(12px);
}

@mixin icon-arrow-prev() {
  @include icon('arrow-prev-usage');

  height: rem(20px);
  width: rem(12px);
}

@mixin icon-hamburger-menu() {
  @include icon('hamburger-menu-usage');

  height: rem(14px);
  width: rem(20px);
}

// ----------------------------------------
// VISUAL STATE
// - accessible visual hiding of content
// ----------------------------------------

/* stylelint-disable declaration-no-important */
// This !important is required to guarantee the element is removed from the view.
@mixin accessibility-hidden {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  width: 1px;
}

/* stylelint-enable declaration-no-important */

// ----------------------------------------
// ANIMATIONS
// ----------------------------------------

// eases icon to the right
@mixin right-nudge {
  margin-left: rem(4px);
  transition: transform map-get($transitions, default);

  *:hover > & {
    transform: translateX(rem(4px));
  }
}

// Infographic Animation
@mixin fade-in {
  opacity: 0;
  transition: opacity map-get($transitions, default-ease-in);

  &.visible {
    opacity: 1;
  }
}

// flips an up/down arrow down or up
@mixin vertical-arrow-flip($parent-selector, $active-selector) {
  transition: transform $map-get($transitions, default);

  #{$parent-selector}:hover & {
    transform: rotateX(180deg);
  }

  @if $active-selector {
    #{$active-selector} & {
      transform: rotateX(180deg);
    }
  }
}

// ----------------------------------------
// FONT HELPERS
// ----------------------------------------

// Paragraph mixins
@mixin text-xs {
  font-size: rem(10);
  line-height: rem(15);
  letter-spacing: rem(15);
  font-weight: map-get($font-weights, bold);
}

@mixin text-sm {
  font-size: rem(12);
  line-height: rem(14);
  letter-spacing: -0.0025rem;
  font-weight: map-get($font-weights, normal);

  @include media('>=desktop') {
    font-size: rem(14);
    line-height: rem(16);
    letter-spacing: -0.0025rem;
    font-weight: map-get($font-weights, normal);
  }
}

@mixin text-base {
  font-size: rem(16);
  line-height: rem(20);
  letter-spacing: -0.25px;
  font-weight: map-get($font-weights, normal);

  @include media('>=desktop') {
    font-size: rem(18);
    line-height: rem(24);
    letter-spacing: -0.25px;
    font-weight: map-get($font-weights, normal);
  }
}

@mixin text-lg {
  font-size: rem(25);
  line-height: rem(32);
  letter-spacing: -0.75px;
  font-weight: map-get($font-weights, normal);
}

@mixin text-xl {
  font-size: rem(21);
  line-height: rem(28);
}

@mixin label {
  font-size: rem(13);
  font-weight: map-get($font-weights, semibold);
  letter-spacing: 0.42px;
  line-height: rem(18);
  text-transform: uppercase;
}

@mixin blog-teaser {
  font-size: rem(16);
  letter-spacing: -0.25px;
  line-height: 1.4;
  font-weight: map-get($font-weights, light);

  @include media('>=desktop') {
    font-size: rem(20);
  }
}

@mixin date {
  font-size: rem(12);
  letter-spacing: -0.25px;
  line-height: rem(17);
  font-weight: map-get($font-weights, semibold);
}

// Headers and titles mixins
@mixin pre-title {
  font-size: rem(12);
  line-height: rem(16);
  letter-spacing: rem(2);
  font-weight: map-get($font-weights, bold);
}

@mixin heading1 {
  font-size: rem(36);
  font-weight: map-get($font-weights, light);
  line-height: rem(40);
  margin-bottom: rem(10);

  &.heading-sm {
    font-size: rem(30);
    line-height: rem(38);
  }

  @include media('>=desktop') {
    font-size: rem(60);
    line-height: rem(62);
    margin-bottom: rem(20);
    &.heading-sm {
      font-size: rem(48);
      line-height: rem(54);
    }
  }
}

@mixin heading2 {
  font-size: rem(32);
  font-weight: map-get($font-weights, light);
  line-height: rem(38);
  margin-bottom: rem(20);

  @include media('>=desktop') {
    font-size: rem(48);
    line-height: rem(54);
    margin-bottom: rem(30);
  }
}

@mixin heading3 {
  font-size: rem(28);
  font-weight: map-get($font-weights, light);
  line-height: rem(34);
  margin-bottom: rem(20);

  @include media('>=desktop') {
    font-size: rem(36);
    line-height: rem(42);
    margin-bottom: rem(25);
  }
}

@mixin heading4 {
  font-size: rem(20);
  font-weight: map-get($font-weights, light);
  line-height: rem(24);

  @include media('>=desktop') {
    font-size: rem(28);
    line-height: rem(32);
  }
}

@mixin heading5 {
  font-size: rem(18);
  font-weight: map-get($font-weights, semibold);
  line-height: rem(26);
}

@mixin ui-heading1 {
  font-size: rem(12);
  font-weight: map-get($font-weights, normal);
  letter-spacing: 0;
  line-height: rem(14);
}

@mixin ui-heading2 {
  font-size: rem(14);
  font-weight: map-get($font-weights, normal);
  letter-spacing: rem(-0.25);
  line-height: rem(16);
}

@mixin ui-heading3 {
  font-size: rem(16);
  font-weight: map-get($font-weights, normal);
  letter-spacing: rem(-0.25);
  line-height: rem(18);
}

@mixin ui-heading4 {
  font-size: rem(20);
  font-weight: map-get($font-weights, normal);
  letter-spacing: rem(-0.5);
  line-height: rem(24);
}

@mixin ui-display1 {
  font-size: rem(18);
  font-weight: map-get($font-weights, normal);
  letter-spacing: rem(-0.25);
  line-height: rem(24);
}

@mixin ui-overline1 {
  font-size: rem(10);
  font-weight: map-get($font-weights, bold);
  letter-spacing: 0;
  line-height: rem(15);
}

@mixin ui-overline2 {
  font-size: rem(13);
  font-weight: map-get($font-weights, bold);
  letter-spacing: rem(2);
  line-height: rem(16);
}

$grid: (
    type: grid,
    default: default,
    generateGridClassesFromBreakpoints: true,
    columns: 12,
    column-gap: 1rem,
    row-gap: 1.5rem
);
$breakpoints: (
    desktop: 64rem,
    dividedteaser: 48em,
    history: 52em,
    history-lg: 74.3em,
    screenSmall: 767px
);
            @import "STYLE_UTILS/_base";$offset: 75px;

.sw-infographic {
  width: 100vw;
  position: relative;
  margin-left: -50vw;
  left: 50%;

  .container > .cmp-container {
    background-size: 200% auto !important;
    background-position-y: rem(280px);
    background-position-x: center;

    @include media('>=screenSmall') {
      background-size: 95% auto !important;
      background-position-y: rem(250px);
      background-position-x: center;
    }
  }

  .sw-infographic--small .cmp-infographic-data .cmp-teaser__pretitle {
    color: var(--sw-hero-background, currentColor);

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: rem(100px);
      height: 1px;
      margin: 0 auto;
      background-color: map-get($colors, body);
    }
  }
}

.cmp-infographic {
  @include sw-container();
  padding: rem(40px) map-get($grid, column-gap) rem(25px);

  @include media('>=desktop') {
    padding-top: rem(70px);
    padding-bottom: rem(50px);
    padding-left: 0;
    padding-right: 0;
  }
}

.cmp-infographic .cmp-title__text {
  font-weight: map-get($font-weights, light);
}

.cmp-infographic .title {
  margin-bottom: rem(25px);
}

.cmp-infographic .text {
  max-width: rem(600px);
}

.cmp-infographic .teaser {
  width: 50%;
  margin-bottom: rem(16px);

  @include media('>=screenSmall') {
    width: calc(25% - #{rem(50px)});
    margin-bottom: 0;
  }

  @include fade-in;
}

.cmp-infographic-images {
  margin: rem(($offset/2) 0);

  @include media('>=screenSmall') {
    margin: rem($offset 0);
  }
}

.cmp-infographic-images .cmp-container,
.cmp-infographic-data .cmp-container {
  display: flex;
  flex-flow: column wrap;
  align-items: center;

  @include media('>=screenSmall') {
    align-items: flex-start;
    justify-content: space-between;
    flex-flow: row wrap;
  }
}

.cmp-infographic-images .title {
  width: 100%;
  text-align: center;
  text-transform: uppercase;
}

.cmp-infographic-images .cmp-title__text {
  margin: 0;
  font-size: rem(18px);
  font-weight: map-get($font-weights, semibold);
}

.cmp-infographic-images .cmp-container {
  max-width: rem(900px);
  margin: 0 auto;
}

.cmp-infographic-images .cmp-teaser {
  padding: 0;
}

.cmp-infographic-images .cmp-teaser__title {
  font-size: rem(16px);
  font-weight: map-get($font-weights, normal);
  letter-spacing: 0;
  text-align: center;
  line-height: rem(20px);
  padding: rem(21px 0);
}

.cmp-infographic-data .teaser {
  margin-bottom: rem(30px);

  @include media('>=screenSmall') {
    margin-bottom: rem(16px);
  }
}

.cmp-infographic-data .cmp-teaser__pretitle {
  position: relative;
  font-size: rem(60px);
  font-weight: map-get($font-weights, normal);

  @include media('>=screenSmall') {
    font-size: rem(40px);
  }

  @include media('>=screenSmall') {
    font-size: rem(60px);
  }
}

.cmp-infographic-data .cmp-teaser__content {
  text-align: center;
}

.cmp-infographic-data .cmp-teaser__title {
  font-size: rem(16px);
  line-height: rem(20px);
  letter-spacing: 0;
  font-weight: map-get($font-weights, normal);
  margin-top: rem(10px);
  padding: rem(21px 0);
}

.cmp-infographic-data .cmp-teaser {
  padding: 0;
}

.cmp-infographic .text:last-child {
  margin-top: rem($offset/2);
  max-width: 100%;
  font-size: rem(12px);

  @include media('>=screenSmall') {
    margin-top: rem($offset);
  }
}
